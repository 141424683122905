import React from "react"

import "./aboutpage.scss"

import VikasGoel from "../../images/management/VikasGoel.jpg"
import SorabhGupta from "../../images/management/SorabhGupta.jpg"
import AnubhavGupta from "../../images/management/AnubhavGupta.jpg"

import whyUs from "../../images/about-why-us/why-us.png"

const AboutPage = () => {
  return (
    <>
      {/* Banner */}
      <div className="about-banner">
        <div className="about-banner-banner"></div>
        <div className="about-banner-content">
          <div className="about-banner-content-heading">
            <b>About Us</b>
          </div>
          <div className="about-banner-content-content">
            <b>Exploring the Savings in Terms of Energy</b>
          </div>
          <div className="about-banner-content-button">
            <b>Welcome</b>
          </div>
        </div>
      </div>

      <div className="about-content">
        {/* History */}
        <div className="about-history-heading">
          <div className="about-history-heading-container">
            <div className="about-history-heading-lead-lines">
              <div className="about-history-heading-lead-line-top"></div>
              <div className="about-history-heading-lead-line-bottom"></div>
            </div>
            <div className="about-history-heading-text">History</div>
          </div>
        </div>
        <div className="about-history-content">
          <p>
            High Street Lighting (HSL), founded in 2015, is a Solar Power
            Company that believes in providing world-class innovative services
            and Solar products. It is established keeping in mind the changing
            nature of our environment and sustainable shift to renewable energy.
            HSL is an ISO 9001:2008 certified technology-oriented company that
            specializes in developing, manufacturing, marketing, and exporting
            Lithium-ion Rechargeable Batteries, Solar Led Street lights, Solar
            Home Lighting systems & other solar powered products.
          </p>
          <p>
            High Street Lighting (HSL) is set to carve a niche in the field of
            sustainable technology due to its unique product range. It is
            extremely recognized in the industry owing to the highly durable and
            qualitative range of lights and batteries.
          </p>
        </div>

        {/* Our Management */}
        <div className="about-management-heading">
          <div className="about-management-heading-container">
            <div className="about-management-heading-text">Our Management</div>
            <div className="about-management-heading-lead-lines">
              <div className="about-management-heading-lead-line-top"></div>
              <div className="about-management-heading-lead-line-bottom"></div>
            </div>
          </div>
        </div>
        <div className="about-management-content">
          <ManagmentPerson
            name="Mr. Vikas Goel"
            src={VikasGoel}
            position="Co-Founder and CEO"
            content="Mr. Vikas Goel is the Co-Founder and CEO of HSL since its inception in 2015. He is a renowned industrialist involved in Textile, Chemicals, Gasses & Real Estate Business. His strength lies in process optimization and manpower handling. Over several years, he has achieved commendable heights with HSL. Mr. Goel holds an MBA from Pune University. He is a first-generation entrepreneur with 27 years of vast experience. He has not only established HSL but has also launched various other successful business ventures."
          />
          <ManagmentPerson
            name="Mr. Sorabh Gupta"
            src={SorabhGupta}
            position="Managing Director of Dhampure Group"
            content="Mr. Sorabh Gupta is the Managing Director of Dhampure Group with expertise in multiple business ventures such as Specialty Sugars, Chemicals, Real Estate, Venture Capital, and Food & Beverage amongst many others. Over several years, Mr. Gupta has scaled up a number of innovative and ground-breaking business verticals alongside diverse stakeholders and technologies. His core strengths lie in networking with semi and all government agencies while establishing successful alliances with the leading brands of the world. A visionary and self-motivated entrepreneur, Mr. Sourabh Gupta believes, “Sharing makes success sweeter”, and is passionate about giving back to the community through opportunities that create ecological and societal benefit."
          />
          <ManagmentPerson
            name="Mr. Anubhav Goel"
            src={AnubhavGupta}
            position="President of Autolite Shanghai Company Limited"
            content="Mr. Anubhav Goel is the President of Autolite Shanghai Company Limited & the Resident Director of “Federation of Indian Micro and Small & Medium Enterprises” (FISME), China Office. He is a renowned businessman with a strong foothold in Automotive Components, Textile, Chemicals, Pharmaceuticals & Real Estate. A sourcing genius, Mr. Goel possesses outstanding capabilities in locating new vendors that support his business goals along with an expert eye for product strategies."
          />
        </div>

        {/* Our Values */}
        <div className="about-values-heading">
          <div className="about-values-heading-container">
            <div className="about-values-heading-lead-lines">
              <div className="about-values-heading-lead-line-top"></div>
              <div className="about-values-heading-lead-line-bottom"></div>
            </div>
            <div className="about-values-heading-text">Our Values</div>
          </div>
        </div>
        <div className="about-values-value">
          <div className="about-values-value-vision">
            <div className="about-values-value-vision-img"></div>
          </div>
          <div className="about-values-value-content">
            <div className="about-values-value-content-heading">Vision</div>
            <div className="about-values-value-content-content">
              To be the responsible leader in providing sustainable energy
              solutions to the world.
            </div>
          </div>
        </div>
        <div className="about-values-value">
          <div className="about-values-value-mission">
            <div className="about-values-value-mission-img"></div>
          </div>
          <div className="about-values-value-content">
            <div className="about-values-value-content-heading">Mission</div>
            <div className="about-values-value-content-content">
              We, at HSL, believe in promoting the value of saving energy
              throughout the world to improve lives, boost economic growth, and
              accelerate the transition to a low-carbon world. HSL’s mission is
              to make small impacts that benefit the larger community in terms
              of sustainable energy.
            </div>
          </div>
        </div>

        {/* Why Us */}
        <div className="about-why-heading">
          <div className="about-why-heading-buffer">
            <div className="about-why-heading-lead-lines">
              <div className="about-why-heading-lead-line-top"></div>
              <div className="about-why-heading-lead-line-bottom"></div>
            </div>
          </div>
          <div className="about-why-heading-text">Why Us?</div>
          <div className="about-why-heading-buffer"></div>
        </div>
        <div className="about-why-content">
          <img
            src={whyUs}
            style={{
              margin: "0 auto",
              maxHeight: "35rem",
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AboutPage

const ManagmentPerson = props => {
  return (
    <div className="about-management-person">
      <div className="about-management-person-img">
        <img src={props.src} alt={props.name} title={props.name} />
      </div>
      <div className="about-management-person-content">
        <div className="about-management-person-content-heading">
          {props.name}
        </div>
        <div className="about-management-person-content-position">
          {props.position}
        </div>
        <div className="about-management-person-content-content">
          {props.content}
        </div>
      </div>
    </div>
  )
}
