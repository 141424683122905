import React from "react"
import SEO from "../components/layout/seo"

import Layout from "../components/layout/layout"
import AboutPage from "../components/about/aboutpage"

const About = () => (
  <Layout home={false}>
    <SEO title="About Us" />
    <AboutPage/>
  </Layout>
)

export default About
